import Image from "next/image";
import { useRouter } from "next/router";
import { BlueButton, RedButton, useMounted } from "ui";
import { useRef, useEffect } from "react";

const EmptyState = ({
  message = "Oh, Such Void",
  description = "",
  showButtons = false,
}) => {
  const router = useRouter();
  const { mounted, isMountedRef } = useMounted();

  return (
    <div
      className="w-full h-full py-32 bg-white dark:bg-gray-900 flex flex-col justify-center place-content-center items-center"
      ref={isMountedRef}
    >
      {/* {mounted && (
        <Image
          src="/void.svg"
          alt="Picture of the author"
          width={300}
          height={300}
        />
      )} */}
      <div className="mt-2 block text-2xl font-medium text-gray-900 dark:text-white">
        {message}
      </div>
      {description !== "" && (
        <div className="mt-2 block text-base font-medium text-gray-900 dark:text-white">
          {description}
        </div>
      )}

      {showButtons && (
        <div className="flex space-x-4 mt-12">
          <BlueButton type="button" onClick={() => router.reload()}>
            Reload the page
          </BlueButton>

          <RedButton type="button" onClick={() => router.back()}>
            Just go back
          </RedButton>
        </div>
      )}
    </div>
  );
};

export default EmptyState;
