import React, { useEffect, useState, useRef } from "react";
import {
  useSelector,
  shallowEqual,
  useDispatch,
  TypedUseSelectorHook,
} from "react-redux";
import { useRouter } from "next/router";
import { teamsUserCanViewTeams, settingsGetTeamSettings } from "services";
import {
  setTeams,
  setLoadedTeams,
  RootState,
  AppDispatch,
  setTeamSettings,
} from "store";
import { Sidebar, Header, MobileNavigation } from "../navigation";
import { themeScheme } from "utils";
import { MenuIcon } from "@heroicons/react/outline";
import { classNames } from "ui";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function Team(props: any) {
  const router = useRouter();

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const {
    currentTeam,
    teams,
    loadedTeams,
    environments,
    currentEnvironment,
    user,
    accessToken,
    settings,
  } = useAppSelector(
    ({ team, auth }) => ({
      currentTeam: team.currentTeam,
      loadedTeams: team.loadedTeams,
      settings: team.settings,
      teams: team.teams,
      environments: team.environments,
      currentEnvironment: team.currentEnvironment,
      user: auth.user,
      accessToken: auth.accessToken,
    }),
    shallowEqual
  );

  useEffect(() => {
    (async () => {
      if (currentTeam && settings === undefined) {
        const teamSettings = await settingsGetTeamSettings();
        dispatch(setTeamSettings(teamSettings));
      }

      if (!user && !accessToken) {
        return;
      }
      if (!loadedTeams) {
        try {
          // We should load teams
          const teams = await teamsUserCanViewTeams("neptune");
          dispatch(setTeams(teams));
          // Set true that we loaded teams
          dispatch(setLoadedTeams(true));
        } catch (error) {}
      } else {
        // Teams are loaded
        // What if user added new team or is part of new team now?
        // New teams can be loaded as part of teams dropdown opening - This will work only user is part of atleast one team already
        // What if user just created a team, because we redirected the user to create team
        if (teams && teams.length === 0) {
          try {
            const userTeams = await teamsUserCanViewTeams("neptune");
            if (userTeams && userTeams.length > 0) {
              dispatch(setTeams(userTeams));
            }
          } catch (error) {}
        }
      }
    })();
  }, [user, accessToken]);

  useEffect(() => {
    if (teams.length === 0 || !currentTeam) {
      router.replace("/teams");
    }
  }, [currentTeam]);

  if (currentTeam) {
    return (
      <>
        <div className="h-screen flex">
          <Sidebar />
          <div className="flex-1 flex flex-col overflow-hidden">
            <header
              className={classNames(
                themeScheme.bg,
                themeScheme.border,
                `w-full flex-shrink-0 relative h-16 flex items-center border-0 border-b `
              )}
            >
              <div className="absolute inset-y-0 right-0 pr-4 flex items-center sm:pr-6 lg:hidden">
                <button
                  type="button"
                  className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <Header
                teams={teams}
                currentTeam={currentTeam}
                environments={environments}
                currentEnvironment={currentEnvironment}
                user={user}
              />
              <MobileNavigation open={open} setOpen={setOpen} />
            </header>
            <div className="flex-1 flex items-stretch overflow-hidden">
              <div className="min-h-0 flex-1 flex overflow-hidden">
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
