export const darkTheme = {
  background: "#111827",
  textColor: "#d1d5db",
  fontSize: 11,
  axis: {
    domain: {
      line: {
        stroke: "#6b7280",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#d1d5db",
      },
    },
    ticks: {
      line: {
        stroke: "#d1d5db",
        strokeWidth: 1,
      },
      text: {
        fontSize: 11,
        fill: "#d1d5db",
      },
    },
  },
  grid: {
    line: {
      stroke: "#6b7280",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: "#d1d5db",
      },
    },
    text: {
      fontSize: 11,
      fill: "#d1d5db",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: "#333333",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#333333",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: "#1f2937",
      color: "#d1d5db",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};
