import React, { useEffect, useState, useRef } from "react";
import {
  useSelector,
  shallowEqual,
  useDispatch,
  TypedUseSelectorHook,
} from "react-redux";
import { RootState, AppDispatch } from "store";
import TeamWrapper from "./team";
import Image from "next/image";
import { classNames } from "ui";
import { themeScheme } from "utils";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function Team(props: any) {
  const { currentEnvironment } = useAppSelector(
    ({ team }) => ({
      currentEnvironment: team.currentEnvironment,
    }),
    shallowEqual
  );

  if (currentEnvironment) {
    return <TeamWrapper {...props}>{props.children}</TeamWrapper>;
  } else {
    return (
      <TeamWrapper {...props}>
        <main className="min-w-0 flex-1 xl:flex">
          <section
            aria-labelledby="message-heading"
            className="min-w-0 flex-1 h-full flex flex-col overflow-hidden xl:order-last"
          >
            <div className="max-h-screen flex-1 overflow-hidden flex flex-col justify-center items-center">
              <div
                className={classNames(
                  themeScheme.border,
                  "max-w-sm mx-auto relative block w-full border-2 rounded-lg px-12 py-16 text-center hover:border-gray-400"
                )}
              >
                <Image
                  src="/select_environment.svg"
                  alt="Picture of the author"
                  width={300}
                  height={300}
                />

                <span className="mt-2 block text-xl font-medium text-gray-900 dark:text-white">
                  Select the environment
                </span>
              </div>
            </div>
          </section>
        </main>
      </TeamWrapper>
    );
  }
}
