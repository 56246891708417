import React, { useEffect, useState, useRef } from "react";
import { classNames } from "ui";
import { themeScheme } from "utils";

export default function MainWrapper({ ready = true, ...props }) {
  useEffect(() => {
    // console.log("Mounted MainWrapper");
  }, []);

  return (
    <>
      <main
        className={classNames(
          "min-w-0 flex-1 initial:xl:flex",
          props.mainClasses
        )}
      >
        {ready && (
          <section
            aria-labelledby="message-heading"
            className="min-w-0 flex-1 h-full flex flex-col overflow-hidden xl:order-last"
          >
            {props.fixed && (
              <div
                className={classNames(
                  themeScheme.border,
                  "flex-shrink-0 border-0 border-b"
                )}
              >
                {props.fixed}
              </div>
            )}

            <div
              className={classNames(
                "max-h-screen flex-1 overflow-y-scroll px-6 pb-8 initial:py-8",
                props.sectionDivClasses
              )}
            >
              {props.children}
            </div>
          </section>
        )}

        {props.aside && <>{props.aside()}</>}
      </main>
    </>
  );
}
