import { ResponsiveBar } from "@nivo/bar";
import { lightTheme } from "./lightTheme";
import { darkTheme } from "./darkTheme";
import { useState, useEffect, useRef } from "react";
import { analyticsCanViewEventsAnalytics } from "services";
import { useTheme } from "next-themes";
import { useDateHelpers, BasicCard, classNames, PulseLoader } from "ui";
import { themeScheme } from "utils";
import { DateTime } from "luxon";

function findNumberOfTicks(width, labelWidth) {
  let smaller = (Math.round(width / 100) * 100) / labelWidth;
  return Math.ceil(smaller);
}

const EventsTimeseriesGraph = ({
  filterStartTime,
  filterEndTime,
  currentEnvironment,
  height = "300px",
}) => {
  useEffect(() => {
    // console.log("EventsTimeseriesGraph Mounted");
  }, []);

  // const [timeseriesEvents, setTimeseriesEvents] = useState([]);
  const [timeseriesEventsLoaded, setTimeseriesEventsLoaded] = useState(false);
  const ref = useRef(null);
  const { getFormattedDate, timeAgo } = useDateHelpers();
  const [timeSize, setTimeSize] = useState("30m");
  const [dateFormat, setDateFormat] = useState("hh:mm a");
  const [barGraphData, setBarGraphData] = useState([]);
  const { theme, setTheme } = useTheme();
  const [labels, setLabels] = useState([]);
  const [graphKeys, setGraphKeys] = useState([]);

  const determineTimeSizeAndDateFormat = (diff) => {
    // console.log("diff.days", diff.days, Math.ceil(diff.days / 20));

    let dateFormatString = "hh:mm a";
    let resolution = "30m";

    if (diff.days && diff.days > 20) {
      dateFormatString = "yyyy-LL-dd";
      resolution = `${Math.ceil(diff.days / 20)}d`;
    } else if (diff.days && diff.days > 10) {
      dateFormatString = "yyyy-LL-dd";
      resolution = `1d`;
    } else if (diff.days && diff.days > 1) {
      dateFormatString = "yyyy-LL-dd hh:mm a";
      resolution = `2h`;
    }

    return { resolution: resolution, dateFormatString: dateFormatString };
  };

  const fetchTimeseries = async () => {
    let diff = DateTime.fromJSDate(new Date(filterEndTime)).diff(
      DateTime.fromJSDate(new Date(filterStartTime)),
      "days"
    );

    let { resolution, dateFormatString } = determineTimeSizeAndDateFormat(
      diff.values
    );

    // console.log("timeSizeAndDateFormat", resolution, dateFormatString);
    setDateFormat(dateFormatString);
    setTimeSize(resolution);

    // console.log("FetchTimeSeries ---", filterStartTime, diff.values);

    setTimeseriesEventsLoaded(false);

    try {
      let timeseriesEvents = await analyticsCanViewEventsAnalytics(
        currentEnvironment["name"],
        {
          presentation: "timeseries",
          startTime: filterStartTime,
          endTime: filterEndTime,
          timeSize: resolution,
        }
      );

      let numberOfTicks = 10;

      if (ref.current) {
        numberOfTicks = findNumberOfTicks(
          ref.current.offsetWidth - 400,
          dateFormatString.length * 11
        );
      }

      const graphKeys = [];

      if (timeseriesEvents?.buckets?.series) {
        let chunk = Math.round(
          timeseriesEvents?.buckets?.series.length / numberOfTicks
        );

        let data = timeseriesEvents?.buckets?.series.map((s, i) => {
          // console.log("dateFormat", dateFormatString);

          let label = getFormattedDate(s.startTime, false, dateFormatString);

          // console.log("s.groups", s.groups);

          let dataPoint = {
            id: `${label}`,
          };

          let totalValue = 0;

          let values = s.groups?.forEach((g) => {
            graphKeys.push(g["group"]["event_type"]);
            dataPoint[g["group"]["event_type"]] = g["aggregations"][0]["value"];
          });

          // console.log("values", values, dataPoint);

          return dataPoint;
        });

        setGraphKeys([...new Set(graphKeys)]);

        let chunkedData = [];
        let labels = [];

        for (let i = 0, j = data.length; i < j; i += chunk) {
          chunkedData = data.slice(i, i + chunk);
          labels.push(chunkedData[0]);
        }

        if (labels.length) {
          labels = labels.map((l) => l.id);
          setLabels(labels);
        }

        setBarGraphData(data);
      }
    } catch (error) {
    } finally {
      setTimeseriesEventsLoaded(true);
    }
  };

  useEffect(() => {
    if (filterStartTime !== null) {
      fetchTimeseries();
    }
  }, [filterStartTime]);

  return (
    <div
      ref={ref}
      className={classNames(
        themeScheme.bg,
        themeScheme.text,
        "flex flex-col justify-center text-center w-full"
      )}
      style={{
        height: height,
      }}
    >
      {timeseriesEventsLoaded === false && (
        <div className="p-6">
          <PulseLoader />
          <PulseLoader />
          <PulseLoader />
        </div>
      )}

      {timeseriesEventsLoaded && (
        <>
          <ResponsiveBar
            data={barGraphData}
            keys={graphKeys}
            margin={{ top: 50, right: 400, bottom: 50, left: 60 }}
            padding={0.3}
            theme={theme === "dark" ? darkTheme : lightTheme}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickValues: labels,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "# events",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            borderColor={"yellow"}
            labelSkipWidth={12}
            labelSkipHeight={12}
            role="application"
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: 120,
                // translateY: 80,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 10,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
          <div
            className={classNames(
              "text-left p-6 text-xs space-x-2",
              themeScheme.secondaryText
            )}
          >
            <span>Aggregation: {timeSize}</span>
            <span>Date Format: {dateFormat}</span>
            <span>Start Time: {filterStartTime}</span>
            <span>End Time: {filterEndTime}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default EventsTimeseriesGraph;
